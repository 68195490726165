import React, {useState, useEffect} from 'react';
import {DatePicker as AntDatePicker, Space} from 'antd';
import moment from 'moment';
import styled from 'styled-components';

const DatePicker = styled(AntDatePicker)`
  padding-left: 10px;
  width: 250px;
  height: 30px;
  border-radius: 5px;
`;

const DateTimePicker = ({
  datetime,
  onChange = () => {},
  dateOnly = false,
  allowNull = false,
  ...params
}) => {
  const [value, setValue] = useState(datetime ? moment(datetime) : null);

  useEffect(() => {
    setValue(datetime ? moment(datetime) : null);
  }, [datetime]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange(newValue ? newValue.toDate() : null);
  };

  return (
    <Space>
      <DatePicker
        value={value}
        onChange={handleChange}
        format={dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'}
        showTime={!dateOnly}
        {...params}
      />
    </Space>
  );
};

export default DateTimePicker;
