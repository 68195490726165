import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
const appConfig = require('../data.json');

export default function Anchor(props) {
  return props.type === 'button' ? (
    <Button {...props} />
  ) : (
    <GastbyLink {...props} />
  );
}

const GastbyLink = styled(Link)`
  color: ${appConfig.colors.link};
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.75;
  }
`;

const Button = styled.button`
  color: ${appConfig.colors.link};
  cursor: pointer;
  transition: opacity 0.3s;

  background-color: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  &:hover {
    opacity: 0.75;
  }
`;
