import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CSVLink} from 'react-csv';
import styled from 'styled-components';
import {message, Spin} from 'antd';
import {useOutlet} from 'reconnect.js';
import {DELIVERY_TYPE} from '../dictionary';
import {Downloading} from '@styled-icons/material-rounded/Downloading';
import {DownloadForOffline} from '@styled-icons/material-rounded/DownloadForOffline';
const appConfig = require('../data.json');

export default function LogisticCsv(props) {
  const {
    logistics_type = '',
    children,
    backgroundColor,
    color,
    style = {},
  } = props;
  const [records, setRecords] = useState([]);
  const [downloaded, setDownLoaded] = useState(false);
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');
  const csvInstance = useRef();

  const getRecords = useCallback(async () => {
    setLoading(true);
    let params = {
      ordering: '-created',
      logistics_type,
      logistics_status: 'tallying',
      is_item_manufacture: true,
      no_page: true,
    };

    try {
      const records = await actions.getLogistics(params);
      if (records.length === 0) {
        message.info('沒有等待出貨（尚未連入追蹤碼）之物流單');
      } else {
        setRecords(records);
        message.success('已取得資料，請再次點擊，下載檔案!');
      }
    } catch (ex) {
      message.warning('取得物流單錯誤');
      console.warn(ex);
    }
    setLoading(false);
  }, [logistics_type]);

  useEffect(() => {
    if (downloaded) {
      getRecords();

      return () => {
        setRecords([]);
        setDownLoaded(false);
      };
    }
  }, [downloaded, getRecords]);

  // useEffect(() => {
  //   if(records.length > 0 && csvInstance && csvInstance.current.link) {
  //     csvInstance.current.link.click();
  //   }
  // }, [records])

  return (
    <Wrapper color={backgroundColor || appConfig.colors.sub} style={style}>
      {downloaded ? (
        <CSVLink
          ref={csvInstance}
          headers={[
            {label: '訂單編號', key: 'display_id'},
            ...(!logistics_type
              ? [
                  {label: '運送方式', key: 'logistics_type'},
                  {label: '物流狀態', key: 'logistics_status'},
                ]
              : []),
            {label: '寄件人姓名', key: 'sender_name'},
            {label: '寄件人電話', key: 'sender_phone'},
            {label: '寄件人地址', key: 'sender_address'},
            {label: '收件人姓名', key: 'receiver_name'},
            {label: '收件人地址', key: 'receiver_address'},
            {label: '收件人電話', key: 'receiver_phone'},
            {label: '件數', key: 'quantity'},
            {label: '備註', key: 'logistics_note'},
          ]}
          data={records.map((element) => {
            return {
              display_id: element.display_id,
              logistics_type: DELIVERY_TYPE[element.logistics_type],
              logistics_status:
                element.logistics_status === 'tallying' ? '等待出貨' : '',
              sender_name: element.is_delivery_private
                ? element.sender_name
                : appConfig.information.name,
              sender_phone: element.is_delivery_private
                ? element.sender_phone
                : appConfig.information.tel,
              sender_address: element.is_delivery_private
                ? [
                    'sender_zip',
                    'sender_city',
                    'sender_district',
                    'sender_address',
                  ]
                    .map((field) => element[field] || '')
                    .join('')
                : appConfig.information.address,
              receiver_name: element.receiver_name,
              receiver_address: [
                'zip_code',
                'receiver_city',
                'receiver_district',
                'receiver_address',
              ]
                .map((field) => element[field] || '')
                .join(''),
              // .toString()
              // .replace(/,/gi, ''),
              receiver_phone: element.receiver_phone,
              quantity: 1,
              logistics_note: element.logistics_note || '易碎品請勿重壓重摔',
            };
          })}
          filename={`出貨單`}
          className="button">
          {loading ? (
            <Downloading
              size={20}
              color={color || '#ffffff'}
              style={{marginRight: 5}}
            />
          ) : (
            <DownloadForOffline
              size={20}
              color={color}
              style={{marginRight: 5}}
            />
          )}
          <div style={{color: color || '#ffffff'}}>{children}</div>
        </CSVLink>
      ) : (
        <div
          className="button"
          onClick={() => {
            alert('僅列出物流狀態 - 等待出貨之物流單');
            setDownLoaded(true);
          }}>
          <Downloading size={20} color={color} style={{marginRight: 5}} />
          <div style={{color: color || '#ffffff'}}>{children}</div>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  justify-content: center;
  height: 36px;
  padding: 6px 15px;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;

  background-color: ${(props) => props.color || ''};
  border-color: ${(props) => props.color || ''};
  display: inline-flex;
  align-items: center;

  & .button {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  &:hover,
  :active {
    background-color: ${(props) => props.color + '99' || ''};
    border-color: ${(props) => props.color + '66' || ''};
  }
`;
