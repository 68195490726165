import React from 'react';

export default function Tag({
  color = '#c7c7c7',
  shape = 'default',
  children,
  style = {},
}) {
  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        color: 'white',
        borderRadius: 20,
        backgroundColor: color,
        padding: shape === 'circle' ? '7px' : '3px 12px',
        height: 28,
        width: shape === 'circle' ? 28 : 'auto',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        ...style,
      }}>
      {shape === 'default' && (
        <div
          style={{
            backgroundColor: 'white',
            width: 8,
            height: 8,
            borderRadius: 4,
            marginRight: 5,
            flexShrink: 0,
          }}
        />
      )}
      {children}
    </div>
  );
}
