import React, {useMemo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Input, Button, message, Select, Table, Switch} from 'antd';
import LogisticDialog from '../../Dialogs/LogisticDialog';
import {getStatusSet, displayStatus} from '../../Utils/LogisticUtil';
import LogisticCsv from '../../Exports/LogisticCsv';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import Anchor from '../../Widgets/Anchor';
import Block from '../../Widgets/Block';
import Tag from '../../Widgets/Tag';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import {DELIVERY_TYPE} from '../../dictionary';
import getOptions from '../../Utils/getOptions';
import Row from '../../Widgets/Row';

const allStatus = {label: '全部', value: 'all'};

const TYPE_OPTIONS = getOptions(DELIVERY_TYPE);

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function LogisticList(props) {
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');
  const [filters, setFilters] = useState({
    search_str: '',
    logistics_type: TYPE_OPTIONS[0].value,
    logistics_status: allStatus.value,
    is_item_manufacture: true,
    shipping_time_before_today: false,
    ...PAGINATION_INIT,
  });
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState();
  const [openDialog, setOpenDialog] = useState(false);

  const changeFilters = (obj) => setFilters((prev) => ({...prev, ...obj}));

  const getRecords = useCallback(async () => {
    let {
      current,
      pageSize,
      logistics_type,
      logistics_status,
      search_str,
      shipping_time_before_today,
    } = filters;

    setLoading(true);

    let params = {
      offset: (current - 1) * pageSize,
      limit: pageSize,
      search: search_str,
      ...(logistics_type !== TYPE_OPTIONS[0].value && {
        logistics_type,
      }),
      ...(logistics_status !== allStatus.value && {
        logistics_status,
      }),
      is_item_manufacture: true,
      shipping_time: shipping_time_before_today
        ? `${new Date().getFullYear()}-${(
            '00' +
            (new Date().getMonth() + 1)
          ).slice(-2)}-${new Date().getDate()}`
        : '',
      order_voided: false,
    };

    try {
      const {results, count} = await actions.getLogistics(params);
      setRecords(results);
      setTotal(count);
    } catch (ex) {
      message.warning('取得物流單錯誤');
    }
    setLoading(false);
  }, [filters]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  const columns = useMemo(
    () => [
      {
        title: '訂單編號',
        render: (_, instance) => (
          <Anchor to={`/order/?id=${instance.order}`}>
            {instance.display_id}
          </Anchor>
        ),
      },
      {
        title: '顧客姓名',
        render: (_, instance) => (
          <Anchor to={`/member/?id=${instance.buyer_id}`}>
            {instance.receiver_name || instance.buyer_name || '-'}
          </Anchor>
        ),
      },
      {
        title: '物流單編號',
        render: (_, instance) => (
          <Anchor
            type="button"
            onClick={() => {
              setSelected(instance);
              setOpenDialog(true);
            }}>{`#${instance.id}`}</Anchor>
        ),
      },
      {
        title: '物流狀態',
        render: (_, instance) => <div>{displayStatus(instance)}</div>,
      },
      {
        title: '運送方式',
        render: (_, instance) => (
          <Row>
            <div>{DELIVERY_TYPE[instance.logistics_type]}</div>
            {instance.is_delivery_private &&
            instance.logistics_type === 'hct' ? (
              <Tag shape="circle" color="#d0543c" style={{marginLeft: 5}}>
                保
              </Tag>
            ) : null}
          </Row>
        ),
      },
      {
        title: '追蹤單號',
        render: (_, instance) => (
          <div>
            {(instance.tracking_number && instance.tracking_number) || '---'}
          </div>
        ),
      },
      {
        title: '建立時間',
        render: (_, instance) => (
          <div>
            {' '}
            {instance.created.slice(0, 10)} {instance.created.slice(11, 16)}
          </div>
        ),
      },
      {
        title: '預計寄貨時間',
        render: (_, instance) =>
          instance.shipping_time ? (
            <div> {instance.shipping_time.slice(0, 10)}</div>
          ) : (
            <div> --- </div>
          ),
      },
      {
        title: '指定收貨時間',
        render: (_, instance) =>
          instance.receipt_time ? (
            <div>
              {' '}
              {instance.receipt_time.slice(0, 10)}{' '}
              {instance.receipt_time.slice(11, 16)}
            </div>
          ) : (
            <div> 未指定 </div>
          ),
      },
    ],
    [],
  );

  return (
    <Wrapper>
      <Block style={{marginBottom: 20}}>
        <div>
          <div className="row">
            <h4>運送方式</h4>
            <Select
              value={filters.logistics_type}
              onChange={(nextValue) => {
                changeFilters({
                  logistics_type: nextValue,
                  logistics_status: allStatus.value,
                  ...PAGINATION_INIT,
                });
              }}
              style={{marginRight: 40}}>
              {TYPE_OPTIONS.map((option, idx) => (
                <Select.Option key={idx} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>

            <h4>物流狀態</h4>
            <Select
              value={filters.logistics_status}
              onChange={(nextValue) => {
                changeFilters({
                  logistics_status: nextValue,
                  ...PAGINATION_INIT,
                });
              }}
              disabled={filters.logistics_type === TYPE_OPTIONS[0].value}>
              {getOptions(getStatusSet({type: filters.logistics_type})).map(
                (option, idx) => (
                  <Select.Option key={idx} value={option.value}>
                    {option.label}
                  </Select.Option>
                ),
              )}
            </Select>
          </div>

          <div className="row">
            <h4>搜尋</h4>
            <Input.Search
              defaultValue={filters.search_str}
              placeholder="訂單編號/購買者/追蹤單號"
              onSearch={(str) =>
                changeFilters({search_str: str, ...PAGINATION_INIT})
              }
              allowClear
            />
          </div>
        </div>

        <div className="divider" />

        <div className="row" style={{margin: 0}}>
          <Switch
            checked={filters.shipping_time_before_today}
            onChange={(checked) => {
              changeFilters({
                shipping_time_before_today: checked,
                ...PAGINATION_INIT,
              });
            }}
          />
          <div style={{marginLeft: 10}}>超過今日物流單</div>

          <div style={{flex: 1}} />
          {/* <div style={{marginRight: 10}}>下載表單</div> */}
          <LogisticCsv style={{marginRight: 5}}>匯出出貨單</LogisticCsv>
          {/* <div style={{marginRight: 10}}>新竹物流匯入單</div> */}
          <LogisticCsv logistics_type="hct">匯出新竹物流單</LogisticCsv>
          <Button
            type="primary"
            onClick={getRecords}
            icon={<Refresh color="#fff" size={20} style={{marginRight: 5}} />}
            style={{marginLeft: 10}}>
            刷新
          </Button>
        </div>
      </Block>
      <Block>
        <h2>
          {filters.logistics_status === 'all'
            ? '全部狀態'
            : displayStatus(filters)}
        </h2>
        <div className="divider" />
        <div>
          <Table
            loading={loading}
            columns={columns}
            dataSource={records}
            rowKey={(item) => item.id}
            pagination={{
              pageSize: filters.pageSize,
              current: filters.current,
              total,
            }}
            onChange={(pagination) =>
              changeFilters({
                pageSize: pagination.pageSize,
                current: pagination.current,
              })
            }
          />
        </div>
      </Block>

      {openDialog && (
        <LogisticDialog
          closeDialog={() => {
            setOpenDialog(false);
            setSelected(null);
          }}
          onUpdate={getRecords}
          order={null}
          logistic={selected}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
