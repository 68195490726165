import React from 'react';

export default function Divider({children, style = {}}) {
  return (
    <div className="row" style={style}>
      <div style={{height: 1, backgroundColor: '#f0f0f0', flexBasis: 40}} />
      <div style={{margin: 20}}>{children}</div>
      <div style={{height: 1, backgroundColor: '#f0f0f0', flex: 1}} />
    </div>
  );
}
